import { resize } from "../../shared/utils";
function getCss({ style: { size_scale } }) {
  const scale = initial => resize(initial, size_scale);
  return [
    {
      selector: " .language-options",
      declarations: {
        display: "flex",
      },
    },
    {
      selector: " .language-option",
      declarations: {
        position: "relative",
        lineHeight: `${scale(20)}px`,
        fontSize: `${scale(14)}px`,
        padding: `${scale(10)}px`,
        transitionProperty: "background-color",
        transitionDuration: "0.2s",
        transitionTimingFunction: "linear",
      },
    },
    {
      selector: " .language-option a",
      declarations: {
        fontWeight: "700",
        textTransform: "uppercase",
        outline: "none",
        textDecoration: "none",
      },
    },
  ];
}

export default getCss;
