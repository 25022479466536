import { h, Fragment } from "preact";

import {
  useStyleTag,
  useLanguageUrls,
  useSwitcherLanguage,
  useResponsiveDisplay,
} from "../../shared/hooks";
import options from "../../shared/options";

import {
  getAvailableLanguages,
  renderSwitcher,
  switchTo,
} from "../../shared/utils";
import Option from "./Option";
import createStyle from "./style.js";

const switcherName = "toggle";

const Switcher = ({ style, opts, colors }) => {
  const [language, setLanguage] = useSwitcherLanguage();
  const urls = useLanguageUrls();
  const responsiveVisible = useResponsiveDisplay(opts);
  const hasStyleTag = useStyleTag({ style, colors }, createStyle, switcherName);

  const languages = getAvailableLanguages();

  function switchLanguage(l) {
    if (options.switcher_editor) {
      return setLanguage(l);
    }
    setLanguage(l);
    switchTo(l);
  }

  if (!responsiveVisible || !languages.length) {
    // @ts-ignore
    return <Fragment />;
  }

  return (
    <div
      data-wg-notranslate
      tabIndex={-1}
      role="listbox"
      aria-label="Language Switcher"
      {...(!hasStyleTag && { style: { display: "none" } })}
      className={`weglot_switcher ${switcherName}`}
      aria-activedescendant={`weglot-language-${language}`}>
      <div className="language-options">
        {languages.map(l => (
          <Option
            colors={colors}
            language={l}
            key={l}
            url={urls[l]}
            onClick={switchLanguage}
            active={language === l}
          />
        ))}
      </div>
    </div>
  );
};

export default Switcher;

export const createSwitcher = renderSwitcher(Switcher, switcherName);
